<template>
    <v-badge
        left
        overlap
        avatar
        :offset-x="badgeOffset"
        :value="isActive"
        color="white"
        class="a-resource-media"
    >
        <template #badge>
            <v-tooltip v-if="secondary" bottom>
                <template #activator="{ on, attrs }">
                    <v-avatar v-bind="attrs" color="white" v-on="on">
                        <v-icon color="primary-color">rotate</v-icon>
                    </v-avatar>
                </template>
                <span>
                    {{ recurringTooltip }}
                </span>
            </v-tooltip>
            <v-tooltip v-if="!secondary && isPremium" bottom>
                <template #activator="{ on, attrs }">
                    <v-avatar v-bind="attrs" color="white" v-on="on">
                        <v-icon color="turquoise">gem</v-icon>
                    </v-avatar>
                </template>
                <span>Premium Distribution</span>
            </v-tooltip>
            <v-tooltip v-if="!secondary && isFinancial" bottom>
                <template #activator="{ on, attrs }">
                    <v-avatar v-bind="attrs" color="white" v-on="on">
                        <v-icon color="purple lighten-1">
                            fa-comment-dollar
                        </v-icon>
                    </v-avatar>
                </template>
                <span>Financial Distribution</span>
            </v-tooltip>
            <v-tooltip v-if="!secondary && isMsn" bottom>
                <template #activator="{ on, attrs }">
                    <v-avatar v-bind="attrs" color="white" v-on="on">
                        <v-icon color="msn-blue">$vuetify.icons.msn</v-icon>
                    </v-avatar>
                </template>
                <span>Microsoft MSN Distribution</span>
            </v-tooltip>
        </template>
        <v-avatar rounded :size="size" color="main-background">
            <v-img v-if="logo" :src="logo" />
            <v-icon v-else color="grey lighten-2" large>image</v-icon>
        </v-avatar>
        <v-tooltip v-if="hasNewComments" bottom z-index="10">
            <template #activator="{ on, attrs }">
                <div
                    v-bind="attrs"
                    class="new-comments--circle new-comments--circle-outer"
                    v-on="on"
                ></div>
            </template>
            <span>New comments in the last 48 hours</span>
        </v-tooltip>
    </v-badge>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component, { mixins } from 'vue-class-component';

import { PrEvents } from '@/mixins';
import { formatDateTime } from '@/utils/helpers';

import type { AuthorRequest } from '@/types/AuthorRequest';
import type { Assignment } from '@/types/Assignment';

const AuthorRequestMediaProps = Vue.extend({
    name: 'AuthorRequestMedia',
    props: {
        request: {
            type: Object as PropType<Partial<AuthorRequest>>,
            default(): AuthorRequest | null {
                return null;
            }
        },
        assignment: {
            type: Object as PropType<Assignment>,
            default(): Assignment | null {
                return null;
            }
        },
        size: {
            type: Number,
            default() {
                return 48;
            }
        },
        secondary: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component
export default class AuthorRequestMedia extends mixins(
    AuthorRequestMediaProps,
    PrEvents
) {
    get logo() {
        if (this.request) {
            const media = this.request.media_resources;

            if (media?.length) {
                return media[0].media_file.relative_filename;
            }
        }
        return false;
    }

    get isActive() {
        return Boolean(
            this.secondary
                ? this.isRecurring
                : this.isPremium || this.isFinancial
        );
    }

    get isPremium() {
        return (
            this.request.latest_assignment?.announcement?.is_premium ?? false
        );
    }

    get isFinancial() {
        return (
            this.request.latest_assignment?.announcement?.is_financial ?? false
        );
    }

    get isMsn() {
        return this.request.latest_assignment?.announcement?.is_msn ?? false;
    }

    get isRecurring() {
        return Boolean(
            this.request?.requested_time &&
                !this.request?.rejected_time &&
                this.request.frequency !== null
        );
    }

    get recurringTooltip() {
        if (this.request.paused_at) {
            return 'Recurring Request';
        }

        return `Next Amp on ${this.nextAmpAt}`;
    }

    get nextAmpAt() {
        if (
            this.request?.latest_assignment &&
            this.request?.requested_time &&
            this.request?.frequency
        ) {
            if (
                this.request?.latest_assignment.created >
                this.request.requested_time
            ) {
                return this.nextAmpAtDate(
                    this.request?.latest_assignment.created,
                    this.request.frequency
                );
            }

            return this.nextAmpAtDate(
                this.request.requested_time,
                this.request.frequency
            );
        }

        return '';
    }

    get hasNewComments() {
        let assignment = this.request.latest_assignment;

        if (this.assignment) {
            assignment = this.assignment;
        }

        const events = assignment?.announcement?.press_release?.pr_events;

        if (!events) {
            return false;
        }

        return this.getNewCommentsForLast48Hours(events);
    }

    get badgeOffset() {
        return !this.secondary && this.isFinancial && this.isPremium ? 30 : 0;
    }

    nextAmpAtDate(date: number, frequency: number) {
        return formatDateTime(
            this.$date.unix(date).add(frequency, 'seconds').unix()
        );
    }
}
</script>

<style lang="scss">
@import '@/components/AResourceMedia/AResourceMedia.scss';
</style>
